import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Card } from 'react-bootstrap';
import axios from 'axios';
import { getIdToken } from '../utils/auth'; // Ensure this path is correct

const OAuth1KeyForm = () => {
  const [consumerKey, setConsumerKey] = useState('');
  const [consumerSecret, setConsumerSecret] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [accessTokenSecret, setAccessTokenSecret] = useState('');
  const [message, setMessage] = useState('');
  const [existingKeys, setExistingKeys] = useState(null);

  useEffect(() => {
    fetchKeys();
  }, []);

  const fetchKeys = async () => {
    try {
      const idToken = await getIdToken();
      const response = await axios.get('/api/twitter/oauth1/keys', {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      if (response.data.consumerKey) {
        setExistingKeys(response.data);
        setConsumerKey(response.data.consumerKey);
        setAccessToken(response.data.accessToken);
      }
    } catch (error) {
      console.error("Error fetching OAuth 1.0a keys: ", error);
      setMessage('Failed to fetch existing OAuth 1.0a keys. Please try again.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const idToken = await getIdToken();
      await axios.post('/api/twitter/oauth1/keys', {
        consumerKey,
        consumerSecret,
        accessToken,
        accessTokenSecret
      }, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      setMessage('OAuth 1.0a keys saved successfully!');
      setConsumerSecret('');
      setAccessTokenSecret('');
      fetchKeys(); // Refresh the displayed keys
    } catch (error) {
      setMessage('Error saving OAuth 1.0a keys. Please try again.');
      console.error("Error saving OAuth 1.0a keys: ", error);
    }
  };

  const handleDelete = async () => {
    try {
      const idToken = await getIdToken();
      await axios.delete('/api/twitter/oauth1/keys', {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      setMessage('OAuth 1.0a keys deleted successfully!');
      setExistingKeys(null);
      setConsumerKey('');
      setConsumerSecret('');
      setAccessToken('');
      setAccessTokenSecret('');
    } catch (error) {
      setMessage('Error deleting OAuth 1.0a keys. Please try again.');
      console.error("Error deleting OAuth 1.0a keys: ", error);
    }
  };

  return (
    <Card className="my-4">
      <Card.Body>
        <Card.Title>Manage OAuth 1.0a Keys</Card.Title>
        {existingKeys ? (
          <div>
            <p><strong>Consumer Key:</strong> {existingKeys.consumerKey}</p>
            <p><strong>Consumer Secret:</strong> {existingKeys.consumerSecret}</p>
            <p><strong>Access Token:</strong> {existingKeys.accessToken}</p>
            <p><strong>Access Token Secret:</strong> {existingKeys.accessTokenSecret}</p>
            <Button variant="danger" onClick={handleDelete}>Delete OAuth 1.0a Keys</Button>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>API Key</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter Consumer Key" 
                value={consumerKey}
                onChange={(e) => setConsumerKey(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>API Key Secret</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Enter Consumer Secret" 
                value={consumerSecret}
                onChange={(e) => setConsumerSecret(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Access Token</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter Access Token" 
                value={accessToken}
                onChange={(e) => setAccessToken(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Access Token Secret</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Enter Access Token Secret" 
                value={accessTokenSecret}
                onChange={(e) => setAccessTokenSecret(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save OAuth 1.0a Keys
            </Button>
          </Form>
        )}
        {message && <Alert variant={message.includes('Error') ? 'danger' : 'success'} className="mt-3">{message}</Alert>}
      </Card.Body>
    </Card>
  );
};

export default OAuth1KeyForm;