import React, { useState, useEffect } from 'react';
import { Card, Alert, Button } from 'react-bootstrap';
import LinkTwitter from '../LinkTwitter';
import { getIdToken } from '../../utils/auth'; // Assurez-vous que le chemin est correct

const LinkTwitterAccount = () => {
  const [twitterLinked, setTwitterLinked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    checkTwitterLinkStatus();
  }, []);

  const checkTwitterLinkStatus = async () => {
    setIsLoading(true);
    try {
      const idToken = await getIdToken();
      const response = await fetch('/api/twitter/user-info', {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      
      setTwitterLinked(response.ok);
    } catch (error) {
      console.error('Failed to check Twitter link status:', error);
      setTwitterLinked(false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRevokeAccess = async () => {
    try {
      const idToken = await getIdToken();
      const response = await fetch('/api/twitter/revoke', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });
      
      if (response.ok) {
        setTwitterLinked(false);
        alert('Twitter access has been revoked successfully.');
      } else {
        const data = await response.json();
        throw new Error(data.error || 'Failed to revoke access');
      }
    } catch (error) {
      console.error('Failed to revoke Twitter access:', error);
      alert(`Failed to revoke Twitter access: ${error.message}`);
    }
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>Link Your Twitter Account</Card.Title>
        <Card.Text>
          You're just one step away from unleashing the power of automated Twitter management! Here's what you need to know:
          <ol>
            <li>You've already created your Twitter app and set up your API keys - great job!</li>
            <li>Now, you need to link your Twitter account to Tweetomatic using OAuth 2.0.</li>
            <li>This step uses the Client ID and Client Secret you provided earlier.</li>
            <li>The OAuth 1.0a keys (API Key, API Secret, Access Token, Access Token Secret) will be used for specific API calls.</li>
            <li>Once linked, you can start using the Twitter API with these limits:</li>
            <ul>
              <li>50 posts per day</li>
              <li>50 post deletions per day</li>
            </ul>
          </ol>
        </Card.Text>
        <Alert variant="info">
          Note: While we use OAuth 2.0 for account linking, some features may utilize the OAuth 1.0a keys you provided earlier for specific API functionalities.
        </Alert>
        <Alert variant="success">
          Let's grow your audience through automated tasks! Start winning more followers and save time with Tweetomatic. 
          Welcome to a new era of efficient Twitter management!
        </Alert>
        <div className="text-center mt-4">
          {isLoading ? (
            <Button variant="secondary" disabled>Loading...</Button>
          ) : twitterLinked ? (
            <Button variant="danger" onClick={handleRevokeAccess}>Revoke Twitter Access</Button>
          ) : (
            <LinkTwitter setTwitterLinked={setTwitterLinked} />
          )}
        </div>
        <Card.Text className="mt-4">
          {twitterLinked ? (
            <>
              Your Twitter account is linked. You can now:
              <ul>
                <li>Schedule tweets for future posting</li>
                <li>Manage your scheduled tweets</li>
                <li>View analytics for your posted tweets</li>
              </ul>
              Enjoy supercharging your Twitter presence!
            </>
          ) : (
            <>
              Once you've linked your account, you'll be able to:
              <ul>
                <li>Schedule tweets for future posting</li>
                <li>Manage your scheduled tweets</li>
                <li>View analytics for your posted tweets</li>
              </ul>
              Get ready to supercharge your Twitter presence!
            </>
          )}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default LinkTwitterAccount;