import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import axios from 'axios';
import moment from 'moment-timezone';
import EditTweetModal from './EditTweetModal';
import { getIdToken } from '../utils/auth';
import { Tabs, Tab, Table, Form, Button, Alert, Image } from 'react-bootstrap';

const ScheduleTweet = () => {
  const { currentUser } = useAuth();
  const [tweet, setTweet] = useState('');
  const [scheduledTime, setScheduledTime] = useState('');
  const [message, setMessage] = useState('');
  const [saving, setSaving] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState('');
  const [scheduledTweets, setScheduledTweets] = useState([]);
  const [editingTweet, setEditingTweet] = useState(null);
  const [groupedTweets, setGroupedTweets] = useState({});
  const [useChatGPT, setUseChatGPT] = useState(false);
  const [chatGPTPrompt, setChatGPTPrompt] = useState('');
  const [hasChatGPTKey, setHasChatGPTKey] = useState(false);
  const [media, setMedia] = useState(null);
  const [mediaPreview, setMediaPreview] = useState(null);

  useEffect(() => {
    if (currentUser) {
      fetchScheduledTweets();
      checkChatGPTKey();
    }
  }, [currentUser]);

  const checkChatGPTKey = async () => {
    try {
      const idToken = await getIdToken();
      const response = await axios.get(`/api/chatgpt/key/${currentUser.uid}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      setHasChatGPTKey(!!response.data.key);
    } catch (error) {
      console.error('Error checking ChatGPT key:', error);
      setHasChatGPTKey(false);
    }
  };

  const fetchScheduledTweets = async () => {
    try {
      const idToken = await getIdToken();
      const response = await axios.get(`/api/twitter/scheduled-tweets`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      const tweets = response.data.map(tweet => ({
        ...tweet,
        scheduledTime: new Date(tweet.scheduledTime),
        countdown: calculateCountdown(new Date(tweet.scheduledTime)),
        tweetText: typeof tweet.tweet === 'object' ? tweet.tweet.text : tweet.tweet,
        mediaUrl: tweet.mediaId ? `/api/twitter/media/${tweet.mediaId}` : null // Ajustez ce chemin selon votre API
      }));
      setScheduledTweets(tweets);
      groupTweetsByDay(tweets);
    } catch (error) {
      console.error('Error fetching scheduled tweets:', error.response?.data || error.message);
      setMessage(`Error fetching scheduled tweets: ${error.response?.data?.error || error.message}`);
    }
  };


  useEffect(() => {
    const timer = setInterval(() => {
      setScheduledTweets(prevTweets => 
        prevTweets.map(tweet => ({
          ...tweet,
          countdown: calculateCountdown(tweet.scheduledTime)
        }))
      );
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const calculateCountdown = (scheduledTime) => {
    const now = moment();
    const scheduled = moment(scheduledTime);
    const duration = moment.duration(scheduled.diff(now));

    if (duration > 0) {
      const days = Math.floor(duration.asDays());
      const hours = duration.hours();
      const minutes = duration.minutes();
      const seconds = duration.seconds();

      if (days > 0) {
        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
      } else if (hours > 0) {
        return `${hours}h ${minutes}m ${seconds}s`;
      } else if (minutes > 0) {
        return `${minutes}m ${seconds}s`;
      } else {
        return `${seconds}s`;
      }
    } else {
      return 'Expired';
    }
  };

  const groupTweetsByDay = (tweets) => {
    const grouped = tweets.reduce((acc, tweet) => {
      const day = moment(tweet.scheduledTime).format('YYYY-MM-DD');
      if (!acc[day]) {
        acc[day] = [];
      }
      acc[day].push(tweet);
      return acc;
    }, {});
    setGroupedTweets(grouped);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentUser) {
      try {
        setSaving(true);
        setMessage('Scheduling tweet...');
  
        const idToken = await getIdToken();
        const scheduledTimeUTC = moment(scheduledTime).utc().format();
  
        let finalTweet = tweet;
        if (useChatGPT && hasChatGPTKey) {
          finalTweet = await generateTweetWithChatGPT(chatGPTPrompt);
        }
  
        const formData = new FormData();
        formData.append('tweet', finalTweet);
        formData.append('scheduledTime', scheduledTimeUTC);
        if (media) {
          formData.append('media', media);
        }
  
        const response = await axios.post('/api/twitter/schedule', formData, {
          headers: {
            'Authorization': `Bearer ${idToken}`,
            'Content-Type': 'multipart/form-data',
          }
        });
  
        setMessage('Tweet scheduled successfully!');
        setTweet('');
        setScheduledTime('');
        setChatGPTPrompt('');
        setMedia(null);
        setMediaPreview(null);
        setSaving(false);
        fetchScheduledTweets();
      } catch (error) {
        console.error('Error scheduling tweet:', error.response?.data || error.message);
        setMessage(`Error scheduling tweet: ${error.response?.data?.error || error.message}`);
        setSaving(false);
      }
    }
  };

  const generateTweetWithChatGPT = async (prompt) => {
    try {
      const idToken = await getIdToken();
      const response = await axios.post('/api/chatgpt/generate-tweet', { prompt }, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      return response.data.tweet;
    } catch (error) {
      console.error('Error generating tweet with ChatGPT:', error);
      throw new Error('Failed to generate tweet with ChatGPT');
    }
  };

  const handleMediaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        setMessage('File size exceeds 5 MB limit');
        return;
      }
      if (!file.type.startsWith('image/') && !file.type.startsWith('video/')) {
        setMessage('Only images and videos are allowed');
        return;
      }
      setMedia(file);
      setMediaPreview(URL.createObjectURL(file));
    }
  };

  const handleEditTweet = (tweet) => {
    setEditingTweet(tweet);
  };

  const handleUpdateTweet = async (updatedTweet) => {
    try {
      const idToken = await getIdToken();
      await axios.put(`/api/twitter/scheduled-tweets/${updatedTweet.id}`, updatedTweet, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      fetchScheduledTweets();
    } catch (error) {
      console.error('Error updating scheduled tweet:', error);
    }
  };

  const handleDeleteScheduledTweet = async (tweetId) => {
    try {
      const idToken = await getIdToken();
      await axios.delete(`/api/twitter/scheduled-tweets/${tweetId}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      fetchScheduledTweets();
    } catch (error) {
      console.error('Error deleting scheduled tweet:', error);
    }
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-6 offset-md-3">
          <div className="card">
            <div className="card-body">
              <h2 className="text-center mb-4">Schedule Tweet</h2>
              {message && <Alert variant="info">{message}</Alert>}
              <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3">
                  <Form.Check 
                    type="checkbox"
                    label="Use ChatGPT to generate tweet"
                    checked={useChatGPT}
                    onChange={(e) => setUseChatGPT(e.target.checked)}
                    disabled={!hasChatGPTKey}
                  />
                  {!hasChatGPTKey && (
                    <Form.Text className="text-muted">
                      Add a ChatGPT API key in the Manage API Keys section to use this feature.
                    </Form.Text>
                  )}
                </Form.Group>
                {useChatGPT ? (
                  <Form.Group className="mb-3">
                    <Form.Label>ChatGPT Prompt</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={chatGPTPrompt}
                      onChange={(e) => setChatGPTPrompt(e.target.value)}
                      required
                    />
                  </Form.Group>
                ) : (
                  <Form.Group className="mb-3">
                    <Form.Label>Tweet</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={tweet}
                      onChange={(e) => setTweet(e.target.value)}
                      required
                    />
                  </Form.Group>
                )}
                <Form.Group className="mb-3">
                  <Form.Label>Scheduled Time</Form.Label>
                  <Form.Control
                    type="datetime-local"
                    value={scheduledTime}
                    onChange={(e) => setScheduledTime(e.target.value)}
                    required
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Media (optional)</Form.Label>
                  <Form.Control
                    type="file"
                    onChange={handleMediaChange}
                    accept="image/*,video/*"
                  />
                  {mediaPreview && (
                    <Image src={mediaPreview} alt="Media preview" fluid className="mt-2" />
                  )}
                </Form.Group>
                <Button variant="primary" type="submit" className="w-100" disabled={saving}>
                  Schedule Tweet
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>

      <div className="mt-5">
        <h3>Scheduled Tweets</h3>
        {Object.keys(groupedTweets).length === 0 ? (
          <p>No scheduled tweets.</p>
        ) : (
          <Tabs defaultActiveKey={Object.keys(groupedTweets)[0]} id="scheduled-tweets-tabs">
            {Object.entries(groupedTweets).map(([day, tweets]) => (
              <Tab eventKey={day} title={moment(day).format('MMM DD, YYYY')} key={day}>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Tweet</th>
                      <th>Scheduled Time</th>
                      <th>Countdown</th>
                      <th>Media</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                  {tweets.map((tweet) => (
                    <tr key={tweet.id}>
                      <td>{tweet.tweetText}</td>
                      <td>{moment(tweet.scheduledTime).format('YYYY-MM-DD HH:mm:ss')}</td>
                      <td>{tweet.countdown}</td>
                      <td>
                        {tweet.mediaUrl && (
                          <Image src={tweet.mediaUrl} alt="Tweet media" fluid width={50} height={50} />
                        )}
                      </td>
                      <td>
                        <Button variant="primary" size="sm" className="me-2" onClick={() => handleEditTweet(tweet)}>
                          Edit
                        </Button>
                        <Button variant="danger" size="sm" onClick={() => handleDeleteScheduledTweet(tweet.id)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
              </Tab>
            ))}
          </Tabs>
        )}
        <EditTweetModal
          tweet={editingTweet}
          onUpdate={handleUpdateTweet}
          onClose={() => setEditingTweet(null)}
        />
      </div>
    </div>
  );
};

export default ScheduleTweet;