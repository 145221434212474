import React, { useEffect, useState } from 'react';
import { Route, Routes, Link, useLocation } from 'react-router-dom';
import ScheduleTweet from './ScheduleTweet';
import LinkTwitter from './LinkTwitter';
import ManageAPIKey from './ManageAPIKey';
import { getIdToken } from '../utils/auth';
import PublishedTweets from './PublishedTweets';

const Dashboard = () => {
  const [twitterLinked, setTwitterLinked] = useState(false);
  const [twitterUsername, setTwitterUsername] = useState('');
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('twitter_linked') === 'true') {
      setTwitterLinked(true);
    }

    const fetchTwitterInfo = async () => {
      try {
        const response = await fetch('/api/twitter/user-info', {
          headers: {
            'Authorization': `Bearer ${await getIdToken()}`
          }
        });
        if (response.ok) {
          const data = await response.json();
          setTwitterLinked(true);
          setTwitterUsername(data.username);
        }
      } catch (error) {
        console.error('Failed to fetch Twitter info:', error);
      }
    };

    fetchTwitterInfo();
  }, [location]);

  return (
    <div>
      {twitterLinked && twitterUsername && (
        <div className="alert alert-success">
          Welcome, @{twitterUsername}!
        </div>
      )}

      <nav className="nav nav-tabs">
        <Link className="nav-link" to="/dashboard/schedule-tweet">Schedule Tweet</Link>
        <Link className="nav-link" to="/dashboard/published-tweets">Published Tweets</Link>        
        <Link className="nav-link" to="/dashboard/link-twitter">Link Twitter Account</Link>
        <Link className="nav-link" to="/dashboard/manage-api-keys">Manage API Keys</Link>
      </nav>

      <div className="tab-content mt-3">
        <Routes>
          <Route path="schedule-tweet" element={<ScheduleTweet />} />
          <Route path="published-tweets" element={<PublishedTweets />} />
          <Route path="link-twitter" element={<LinkTwitter twitterLinked={twitterLinked} />} />
          <Route path="manage-api-keys" element={<ManageAPIKey />} />
        </Routes>
      </div>
    </div>
  );
};

export default Dashboard;