import React, { useState, useEffect } from 'react';
import { Card, Alert, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { getIdToken } from '../../utils/auth'; // Assurez-vous que ce chemin est correct
import './CreateDevAccount.css';

const CreateDevAccount = () => {
  const [apiKey, setApiKey] = useState('');
  const [apiKeySecret, setApiKeySecret] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [accessTokenSecret, setAccessTokenSecret] = useState('');
  const [message, setMessage] = useState('');
  const [userId, setUserId] = useState(null);
  const [existingKeys, setExistingKeys] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        fetchExistingKeys(user.uid);
      } else {
        setUserId(null);
        setExistingKeys(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchExistingKeys = async (uid) => {
    try {
      const idToken = await getIdToken();
      const response = await axios.get('/api/twitter/oauth1/keys', {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      if (response.data) {
        setExistingKeys(response.data);
      }
    } catch (error) {
      console.error("Error fetching existing OAuth 1.0a keys: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userId) {
      setMessage('User not authenticated. Please log in.');
      return;
    }
    try {
      const idToken = await getIdToken();
      await axios.post('/api/twitter/oauth1/keys', {
        consumerKey: apiKey,
        consumerSecret: apiKeySecret,
        accessToken,
        accessTokenSecret
      }, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      setMessage('OAuth 1.0a keys saved successfully!');
      fetchExistingKeys(userId);
      // Reset form fields
      setApiKey('');
      setApiKeySecret('');
      setAccessToken('');
      setAccessTokenSecret('');
    } catch (error) {
      setMessage('Error saving OAuth 1.0a keys. Please try again.');
      console.error("Error saving OAuth 1.0a keys: ", error);
    }
  };

  const handleDelete = async () => {
    if (!userId) {
      setMessage('User not authenticated. Please log in.');
      return;
    }
    try {
      const idToken = await getIdToken();
      await axios.delete('/api/twitter/oauth1/keys', {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      setMessage('OAuth 1.0a keys deleted successfully!');
      setExistingKeys(null);
    } catch (error) {
      setMessage('Error deleting OAuth 1.0a keys. Please try again.');
      console.error("Error deleting OAuth 1.0a keys: ", error);
    }
  };

  return (
    <div className="create-dev-account">
      <h2>Create Developer Account</h2>
      <p>To create a new X (formerly Twitter) developer account and app, follow these detailed steps:</p>
      <ol className="steps-list">
        <li>
          Go to the X Developer Portal: 
          <a href="https://developer.twitter.com/en/portal/projects-and-apps" target="_blank" rel="noopener noreferrer">
            https://developer.twitter.com/en/portal/projects-and-apps
          </a>
          <Alert variant="warning" className="mt-2">
            Note: On Android devices, this link might open the X app instead of the website. If this happens, please copy the link and open it in a web browser.
          </Alert>
        </li>
        <li>
          Once on the Developer Portal page, scroll down to the bottom and click on the "<strong>Sign up for Free Account</strong>" link in the middle of the page.
        </li>
        <li>
          Fill out the application form:
          <ul>
            <li>
              In the first text area, you can use the following text or write your own (minimum 250 characters):
              <Alert variant="info" className="mt-2">
                "I would like to explore and use X's API for my personal use and to manage my tweets efficiently. This will help me organize my content better and enhance my engagement on the platform. All usage will comply with data protection policies. And I want to schedule some tweets."
              </Alert>
            </li>
            <li>Make sure to accept all the terms and conditions presented in the form.</li>
          </ul>
        </li>
        <li>
          Submit your application. Don't worry about approval - you will automatically be granted access to the free tier API, which is sufficient for using the functions of tweetomatic.xyz.
        </li>
        <li>
          After approval, return to the Developer Portal and click on "Create Project" or "Create App" if it's your first app.
        </li>
        <li>
          Once your app is created, you will receive your API Key, API Key Secret, Access Token, and Access Token Secret. Enter these in the form below:
        </li>
      </ol>
      
      <Card className="mt-4">
        <Card.Body>
          <Card.Title>OAuth 1.0a API Keys</Card.Title>
          {existingKeys ? (
            <div>
              <p><strong>API Key:</strong> {existingKeys.consumerKey}</p>
              <p><strong>API Key Secret:</strong> {existingKeys.consumerSecret}</p>
              <p><strong>Access Token:</strong> {existingKeys.accessToken}</p>
              <p><strong>Access Token Secret:</strong> {existingKeys.accessTokenSecret}</p>
              <Button variant="danger" onClick={handleDelete}>Delete OAuth 1.0a Keys</Button>
            </div>
          ) : (
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>API Key</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Enter API Key" 
                  value={apiKey}
                  onChange={(e) => setApiKey(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>API Key Secret</Form.Label>
                <Form.Control 
                  type="password" 
                  placeholder="Enter API Key Secret" 
                  value={apiKeySecret}
                  onChange={(e) => setApiKeySecret(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Access Token</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Enter Access Token" 
                  value={accessToken}
                  onChange={(e) => setAccessToken(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Access Token Secret</Form.Label>
                <Form.Control 
                  type="password" 
                  placeholder="Enter Access Token Secret" 
                  value={accessTokenSecret}
                  onChange={(e) => setAccessTokenSecret(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Save OAuth 1.0a Keys
              </Button>
            </Form>
          )}
          {message && <Alert variant={message.includes('Error') ? 'danger' : 'success'} className="mt-3">{message}</Alert>}
        </Card.Body>
      </Card>

      <Card className="mt-4 bg-light">
        <Card.Body>
          <Card.Title>Important Note</Card.Title>
          <Card.Text>
            You will need both the OAuth 1.0a keys (API Key, API Key Secret, Access Token, Access Token Secret) entered above, and the OAuth 2.0 keys (Client ID, Client Secret) which you'll enter in the next step, for full functionality of the application.
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};

export default CreateDevAccount;