import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Alert, Accordion } from 'react-bootstrap';
import axios from 'axios';
import { getAuth } from 'firebase/auth';

const SetupApp = () => {
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [message, setMessage] = useState('');
  const [existingKeys, setExistingKeys] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        fetchExistingKeys(user.uid);
      } else {
        setUserId(null);
        setExistingKeys(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchExistingKeys = async (uid) => {
    try {
      const response = await axios.get(`/api/client-credentials/${uid}`);
      if (response.data.clientId && response.data.clientSecret) {
        setExistingKeys({
          clientId: response.data.clientId,
          clientSecret: response.data.clientSecret
        });
      }
    } catch (error) {
      console.error("Error fetching existing keys: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userId) {
      setMessage('User not authenticated. Please log in.');
      return;
    }
    try {
      await axios.post('/api/client-credentials', {
        userId,
        clientId,
        clientSecret
      });
      setMessage('Client credentials saved successfully!');
      setClientId('');
      setClientSecret('');
      fetchExistingKeys(userId);
    } catch (error) {
      setMessage('Error saving client credentials. Please try again.');
      console.error("Error saving client credentials: ", error);
    }
  };

  const handleDelete = async () => {
    if (!userId) {
      setMessage('User not authenticated. Please log in.');
      return;
    }
    try {
      await axios.delete(`/api/client-credentials/${userId}`);
      setMessage('Client credentials deleted successfully!');
      setExistingKeys(null);
    } catch (error) {
      setMessage('Error deleting client credentials. Please try again.');
      console.error("Error deleting client credentials: ", error);
    }
  };

  return (
    <Card className="my-4">
      <Card.Body>
        <Card.Title className="mb-4">Setup Twitter App</Card.Title>

        <Accordion defaultActiveKey="0" className="mb-4">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Step-by-Step Instructions</Accordion.Header>
            <Accordion.Body>
              <ol>
                <li>Now that your dev account is created, you need to set up the app.</li>
                <li>Click on <strong>Create Project</strong>, then:</li>
                <li>
                  Fill in the required information about your app:
                  <ul>
                    <li>App name: Choose a unique name for your app</li>
                    <li>Description: Provide a brief description of your app's purpose</li>
                  </ul>
                </li>
                <li>Review and accept the Twitter Developer Agreement and Policy</li>
                <li>Click "Create" to finalize your app</li>
                <li>You should receive your user tokens (save them somewhere secure and don't share them)</li>
                <li>Click on "App settings"</li>
                <li>Click on "Setup" in "User authentication settings"</li>
                <li>In "App Permissions", select "Read and write"</li>
                <li>For "Type of App", choose "Web app, automated app or bot"</li>
                <li>In App info, set:
                  <ul>
                    <li><strong>Callback URI / Redirect URL</strong> (required): https://tweetomatic.xyz/auth/twitter/callback</li>
                    <li><strong>Website URL</strong> (required): https://tweetomatic.xyz</li>
                  </ul>
                </li>
                <li><strong>Important:</strong> Make sure to enter these URLs exactly as shown, character for character, or it won't work!</li>
              </ol>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>

        <Card.Text>
          {existingKeys ? 'Your current API keys:' : 'Enter your Twitter app client ID and client secret:'}
        </Card.Text>
        {existingKeys ? (
          <div>
            <p><strong>Client ID:</strong> {existingKeys.clientId}</p>
            <p><strong>Client Secret:</strong> {existingKeys.clientSecret}</p>
            <Button variant="danger" onClick={handleDelete}>Delete API Keys</Button>
          </div>
        ) : (
          <>
            <Card.Text className="mt-4 mb-3">
              Note your keys in a secure document. You can then enter your keys in the form below to activate Tweetomatic services. If you prefer, you can also do this later.
            </Card.Text>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Client ID</Form.Label>
                <Form.Control 
                  type="text" 
                  placeholder="Enter Client ID" 
                  value={clientId}
                  onChange={(e) => setClientId(e.target.value)}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Client Secret</Form.Label>
                <Form.Control 
                  type="password" 
                  placeholder="Enter Client Secret" 
                  value={clientSecret}
                  onChange={(e) => setClientSecret(e.target.value)}
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Save API Keys
              </Button>
            </Form>
          </>
        )}
        {message && <Alert variant={message.includes('Error') ? 'danger' : 'success'} className="mt-3">{message}</Alert>}
      </Card.Body>
    </Card>
  );
};

export default SetupApp;