import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const EditTweetModal = ({ tweet, onUpdate, onClose }) => {
  const [tweetContent, setTweetContent] = useState(tweet?.tweet || '');

  const handleSubmit = (e) => {
    e.preventDefault();
    onUpdate({ ...tweet, tweet: tweetContent });
  };

  return (
    <Modal show={!!tweet} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Edit Scheduled Tweet</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="tweetContent">
            <Form.Label>Tweet Content</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={tweetContent}
              onChange={(e) => setTweetContent(e.target.value)}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Save Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default EditTweetModal;