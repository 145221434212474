import React, { useState, useEffect } from 'react';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import axios from 'axios';
import { getAuth } from 'firebase/auth';
import { getIdToken } from '../utils/auth'; // Assurez-vous que ce chemin est correct
import OAuth1KeyForm from './OAuth1KeyForm ';

const ManageAPIKey = () => {
  const [clientId, setClientId] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [chatGPTKey, setChatGPTKey] = useState('');
  const [message, setMessage] = useState('');
  const [existingKeys, setExistingKeys] = useState(null);
  const [existingChatGPTKey, setExistingChatGPTKey] = useState(null);
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUserId(user.uid);
        fetchExistingKeys(user.uid);
        fetchExistingChatGPTKey(user.uid);
      } else {
        setUserId(null);
        setExistingKeys(null);
        setExistingChatGPTKey(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const fetchExistingKeys = async (uid) => {
    try {
      const idToken = await getIdToken();
      const response = await axios.get(`/api/client-credentials/${uid}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      if (response.data.clientId && response.data.clientSecret) {
        setExistingKeys({
          clientId: response.data.clientId,
          clientSecret: response.data.clientSecret
        });
      }
    } catch (error) {
      console.error("Error fetching existing keys: ", error);
    }
  };

  const fetchExistingChatGPTKey = async (uid) => {
    try {
      const idToken = await getIdToken();
      const response = await axios.get(`/api/chatgpt/key/${uid}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      if (response.data.key) {
        setExistingChatGPTKey(response.data.key);
      }
    } catch (error) {
      console.error("Error fetching existing ChatGPT key: ", error);
      if (error.response && error.response.status === 404) {
        setExistingChatGPTKey(null);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userId) {
      setMessage('User not authenticated. Please log in.');
      return;
    }
    try {
      const idToken = await getIdToken();
      await axios.post('/api/client-credentials', {
        userId,
        clientId,
        clientSecret
      }, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      setMessage('Client credentials saved successfully!');
      setClientId('');
      setClientSecret('');
      fetchExistingKeys(userId);
    } catch (error) {
      setMessage('Error saving client credentials. Please try again.');
      console.error("Error saving client credentials: ", error);
    }
  };


  
  const handleDelete = async () => {
    if (!userId) {
      setMessage('User not authenticated. Please log in.');
      return;
    }
    try {
      const idToken = await getIdToken();
      await axios.delete(`/api/client-credentials/${userId}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      setMessage('Client credentials deleted successfully!');
      setExistingKeys(null);
    } catch (error) {
      setMessage('Error deleting client credentials. Please try again.');
      console.error("Error deleting client credentials: ", error);
    }
  };

  const handleChatGPTSubmit = async (e) => {
    e.preventDefault();
    if (!userId) {
      setMessage('User not authenticated. Please log in.');
      return;
    }
    try {
      const idToken = await getIdToken();
      await axios.post('/api/chatgpt/key', {
        userId,
        key: chatGPTKey
      }, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      setMessage('ChatGPT API key saved successfully!');
      setChatGPTKey('');
      fetchExistingChatGPTKey(userId);
    } catch (error) {
      setMessage('Error saving ChatGPT API key. Please try again.');
      console.error("Error saving ChatGPT API key: ", error);
    }
  };

  const handleChatGPTDelete = async () => {
    if (!userId) {
      setMessage('User not authenticated. Please log in.');
      return;
    }
    try {
      const idToken = await getIdToken();
      await axios.delete(`/api/chatgpt/key/${userId}`, {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      setMessage('ChatGPT API key deleted successfully!');
      setExistingChatGPTKey(null);
    } catch (error) {
      setMessage('Error deleting ChatGPT API key. Please try again.');
      console.error("Error deleting ChatGPT API key: ", error);
    }
  };

  return (
    <Card className="my-4">
      <Card.Body>
        <Card.Title className="mb-4">Manage API Keys</Card.Title>

        <h5>Twitter API Keys (OAuth 2.0)</h5>
        {existingKeys ? (
          <div>
            <p><strong>Client ID:</strong> {existingKeys.clientId}</p>
            <p><strong>Client Secret:</strong> {existingKeys.clientSecret}</p>
            <Button variant="danger" onClick={handleDelete}>Delete API Keys</Button>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Client ID</Form.Label>
              <Form.Control 
                type="text" 
                placeholder="Enter Client ID" 
                value={clientId}
                onChange={(e) => setClientId(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Client Secret</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Enter Client Secret" 
                value={clientSecret}
                onChange={(e) => setClientSecret(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save Twitter API Keys
            </Button>
          </Form>
        )}

        <h5 className="mt-4">Twitter API Keys (OAuth 1.0a)</h5>
        <OAuth1KeyForm />

        <h5 className="mt-4">ChatGPT API Key</h5>
        {existingChatGPTKey ? (
          <div>
            <p><strong>API Key:</strong> {existingChatGPTKey.substring(0, 5)}{'*'.repeat(existingChatGPTKey.length - 5)}</p>
            <Button variant="danger" onClick={handleChatGPTDelete}>Delete ChatGPT API Key</Button>
          </div>
        ) : (
          <Form onSubmit={handleChatGPTSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>ChatGPT API Key</Form.Label>
              <Form.Control 
                type="password" 
                placeholder="Enter ChatGPT API Key" 
                value={chatGPTKey}
                onChange={(e) => setChatGPTKey(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Save ChatGPT API Key
            </Button>
          </Form>
        )}

        {message && <Alert variant={message.includes('Error') ? 'danger' : 'success'} className="mt-3">{message}</Alert>}
      </Card.Body>
    </Card>
  );
};

export default ManageAPIKey;