import React, { useState } from 'react';
import { getIdToken } from '../utils/auth';

const LinkTwitter = ({ twitterLinked, setTwitterLinked }) => {
  const [isRevoking, setIsRevoking] = useState(false);

  const handleLinkTwitter = async () => {
    try {
      const response = await fetch('/api/twitter/login', {
        headers: {
          'Authorization': `Bearer ${await getIdToken()}`
        }
      });
      const data = await response.json();
      window.location.href = data.url;
    } catch (error) {
      console.error('Failed to initiate Twitter linking:', error);
      alert('Failed to initiate Twitter linking. Please try again.');
    }
  };

  const handleRevokeAccess = async () => {
    setIsRevoking(true);
    try {
      const idToken = await getIdToken();
      const response = await fetch('/api/twitter/revoke', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        }
      });
      
      const data = await response.json();
      
      if (response.ok) {
        setTwitterLinked(false);
        alert('Twitter access has been revoked successfully.');
      } else {
        throw new Error(data.error || 'Failed to revoke access');
      }
    } catch (error) {
      console.error('Failed to revoke Twitter access:', error);
      alert(`Failed to revoke Twitter access: ${error.message}`);
    } finally {
      setIsRevoking(false);
    }
  };

  const checkTwitterLinkStatus = async () => {
    try {
      const idToken = await getIdToken();
      const response = await fetch('/api/twitter/user-info', {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });
      
      if (response.ok) {
        setTwitterLinked(true);
      } else {
        setTwitterLinked(false);
      }
    } catch (error) {
      console.error('Failed to check Twitter link status:', error);
      setTwitterLinked(false);
    }
  };

  React.useEffect(() => {
    checkTwitterLinkStatus();
  }, []);

  return (
    <div>
      <h2>Link Twitter Account</h2>
      {twitterLinked ? (
        <div>
          <p>Your Twitter account is linked. You can now schedule tweets and use other Twitter features.</p>
          <button 
            onClick={handleRevokeAccess} 
            className="btn btn-danger"
            disabled={isRevoking}
          >
            {isRevoking ? 'Revoking...' : 'Revoke Access of this Account'}
          </button>
        </div>
      ) : (
        <button onClick={handleLinkTwitter} className="btn btn-primary">
          Link Twitter Account
        </button>
      )}
    </div>
  );
};

export default LinkTwitter;