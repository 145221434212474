import React, { useState } from 'react';
import { Tab, Nav, Container, Row, Col, Button, Card } from 'react-bootstrap';
import HowToStart from './HowToStart';
import CreateDevAccount from './CreateDevAccount';
import SetupApp from './SetupApp';
import LinkTwitterAccount from './LinkTwitterAccount';
import './index.css';

const TwitterSetupTutorial = () => {
  const [activeTab, setActiveTab] = useState('howToStart');

  const steps = [
    { key: 'howToStart', title: 'How to Start', component: HowToStart },
    { key: 'createApp', title: 'Create Developer Account', component: CreateDevAccount },
    { key: 'setupApp', title: 'Setup App', component: SetupApp },
    { key: 'linkAccount', title: 'Link X Account', component: LinkTwitterAccount },
  ];

  const handleNextStep = () => {
    const currentIndex = steps.findIndex(step => step.key === activeTab);
    if (currentIndex < steps.length - 1) {
      setActiveTab(steps[currentIndex + 1].key);
    }
  };

  const handlePreviousStep = () => {
    const currentIndex = steps.findIndex(step => step.key === activeTab);
    if (currentIndex > 0) {
      setActiveTab(steps[currentIndex - 1].key);
    }
  };

  return (
    <Container className="twitter-setup-tutorial mt-5">
      <h1 className="text-center mb-4">TweetoMatic Setup Tutorial</h1>
      <Card>
        <Card.Body>
          <Tab.Container activeKey={activeTab} onSelect={(k) => setActiveTab(k)}>
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column">
                  {steps.map((step, index) => (
                    <Nav.Item key={step.key}>
                      <Nav.Link eventKey={step.key} className="d-flex align-items-center">
                        <span className="step-number">{index + 1}</span>
                        {step.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  {steps.map((step) => (
                    <Tab.Pane key={step.key} eventKey={step.key}>
                      <step.component />
                      <div className="d-flex justify-content-between mt-4">
                        <Button 
                          variant="primary" 
                          onClick={handlePreviousStep}
                          disabled={step.key === 'howToStart'}
                        >
                          Previous
                        </Button>
                        <Button 
                          variant="primary" 
                          onClick={handleNextStep}
                          disabled={step.key === 'linkAccount'}
                        >
                          {step.key === 'linkAccount' ? 'Finish' : 'Next'}
                        </Button>
                      </div>
                    </Tab.Pane>
                  ))}
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default TwitterSetupTutorial;