import React from 'react';
import { Card, ListGroup } from 'react-bootstrap';

const HowToStart = () => {
  return (
    <Card className="my-4">
      <Card.Body>
        <Card.Title>How to Start</Card.Title>
        <Card.Text>
          Welcome to the Twitter API Setup Tutorial! This guide will walk you through the process of setting up your Twitter API access. Follow these steps carefully:
        </Card.Text>
        <ListGroup variant="flush">
          <ListGroup.Item>
            1. Create a Twitter Developer account at <a href="https://developer.twitter.com" target="_blank" rel="noopener noreferrer">developer.twitter.com</a>
          </ListGroup.Item>
          <ListGroup.Item>
            2. Create a new Twitter application in your developer account
          </ListGroup.Item>
          <ListGroup.Item>
            3. Set up your application's permissions and generate the following keys:
            <ul>
              <li>API Key (Consumer Key)</li>
              <li>API Key Secret (Consumer Secret)</li>
              <li>Access Token</li>
              <li>Access Token Secret</li>
            </ul>
          </ListGroup.Item>
          <ListGroup.Item>
            4. Set up OAuth 2.0 settings for your app and note down:
            <ul>
              <li>Client ID</li>
              <li>Client Secret</li>
            </ul>
          </ListGroup.Item>
          <ListGroup.Item>
            5. In the next step, you'll enter all these keys to configure your app for use with our service
          </ListGroup.Item>
          <ListGroup.Item>
            6. After setting up your keys, you'll be able to link your Twitter account and start using our features
          </ListGroup.Item>
        </ListGroup>
        <Card.Text className="mt-3">
          Click "Next" when you're ready to proceed with entering your API keys.
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default HowToStart;