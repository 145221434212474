import React, { useState, useEffect } from 'react';
import { Card, Button, Spinner, Modal } from 'react-bootstrap';
import { getIdToken } from '../utils/auth';
import './PublishedTweets.css';

const PublishedTweets = () => {
  const [tweets, setTweets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [tweetToDelete, setTweetToDelete] = useState(null);

  useEffect(() => {
    fetchPublishedTweets();
  }, []);

  const fetchPublishedTweets = async () => {
    try {
      const idToken = await getIdToken();
      const response = await fetch('/api/twitter/published-tweets', {
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to fetch published tweets');
      }

      const data = await response.json();
      setTweets(data);
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  const handleDeleteClick = (tweet) => {
    setTweetToDelete(tweet);
    setShowDeleteModal(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const idToken = await getIdToken();
      const response = await fetch(`/api/twitter/published-tweets/${tweetToDelete.id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${idToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to delete tweet');
      }

      setTweets(tweets.filter(tweet => tweet.id !== tweetToDelete.id));
      setShowDeleteModal(false);
      setTweetToDelete(null);
    } catch (err) {
      setError(err.message);
    }
  };

  if (loading) {
    return <Spinner animation="border" role="status" className="d-block mx-auto" />;
  }

  if (error) {
    return <div className="alert alert-danger">{error}</div>;
  }

  return (
    <div className="published-tweets">
      <h2>Published Tweets</h2>
      {tweets.length === 0 ? (
        <p>No tweets have been published yet.</p>
      ) : (
        tweets.map(tweet => (
          <Card key={tweet.id} className="mb-3 tweet-card">
            <Card.Body>
              <Card.Text>{tweet.text}</Card.Text>
              <div className="tweet-meta">
                <small className="text-muted">Published on: {new Date(tweet.created_at).toLocaleString()}</small>
                <br />
                <small className="text-muted">Likes: {tweet.likes} | Retweets: {tweet.retweets}</small>
              </div>
              <Button 
                variant="outline-danger" 
                size="sm" 
                onClick={() => handleDeleteClick(tweet)}
                className="mt-2"
              >
                Delete
              </Button>
            </Card.Body>
          </Card>
        ))
      )}

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this tweet?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirm}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PublishedTweets;