// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// src/firebaseConfig.js
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZNvtGg-EioR_uNLTiyX44991J9im-sOk",
  authDomain: "tweetomatic-b9537.firebaseapp.com",
  projectId: "tweetomatic-b9537",
  storageBucket: "tweetomatic-b9537.appspot.com",
  messagingSenderId: "939790240658",
  appId: "1:939790240658:web:09e6f068e43e6a6877cf4b",
  measurementId: "G-LZ63XWQ8MQ"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const googleProvider = new GoogleAuthProvider();

export const getIdToken = async () => {
  const user = auth.currentUser;
  if (user) {
    return user.getIdToken();
  }
  throw new Error('User not authenticated');
};